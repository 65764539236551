import * as types from "../../constants";
import {
  signIn as authSignIn,
  signUp as authSignUp,
  resetPassword as authResetPassword,
  authInit as authInitService,
} from "../../services/authService";
import { AUTH_TOKEN } from "../../constants";

export function authInit() {
  return async (dispatch) => {
    let token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
      return authInitService(token)
        .then((response) => {
          dispatch({
            type: types.AUTH_SIGN_IN_SUCCESS,
            email: response.email,
            name: response.first_name + " " + response.last_name,
            role: response.role,
          });
        })
        .catch((error) => {
          throw error;
        });
    } else {
      return dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
    }
  };
}
export function signIn(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });
    
    return authSignIn(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          email: response.email,
          name: response.first_name + " " + response.last_name,
          role: response.role,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}
export function signUp(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUp(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
          id: response.id,
          email: response.email,
          name: response.name,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function signOut() {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}

export function resetPassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}
