// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_TOKEN = "AUTH_TOKEN";
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// Accounts
export const SET_ACCOUNT_STACK = "SET_ACCOUNT_STACK";
export const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAILURE = "ADD_ACCOUNT_FAILURE";
export const EDIT_ACCOUNT_SUCCESS = "EDIT_ACCOUNT_SUCCESS";
export const EDIT_ACCOUNT_FAILURE = "EDIT_ACCOUNT_FAILURE";
export const ACCOUNTS_FETCH_SUCCESS = "ACCOUNTS_FETCH_SUCCESS";
export const ACCOUNTS_FETCH_FAILURE = "ACCOUNTS_FETCH_FAILURE";

// Child Accounts
export const CHILD_ACCOUNTS_FETCH_SUCCESS = "CHILD_ACCOUNTS_FETCH_SUCCESS";
export const CHILD_ACCOUNTS_FETCH_FAILURE = "CHILD_ACCOUNTS_FETCH_FAILURE";

// Users
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";
export const USERS_FETCH_SUCCESS = "USERS_FETCH_SUCCESS";
export const USERS_FETCH_FAILURE = "USERS_FETCH_FAILURE";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

// Devices
export const DEVICES_FETCH_SUCCESS = "DEVICES_FETCH_SUCCESS";
export const DEVICES_FETCH_FAILURE = "DEVICES_FETCH_FAILURE";

// Scales
export const SCALES_FETCH_SUCCESS = "SCALES_FETCH_SUCCESS";
export const SCALES_FETCH_FAILURE = "SCALES_FETCH_FAILURE";

// Meal Count
export const FETCH_MEAL_COUNT_SUCCESS = "FETCH_MEAL_COUNT_SUCCESS";
export const FETCH_MEAL_COUNT_FAILURE = "FETCH_MEAL_COUNT_FAILURE";
export const DELETE_MEAL_COUNT_SUCCESS = "DELETE_MEAL_COUNT_SUCCESS";
export const DELETE_MEAL_COUNT_FAILURE = "DELETE_MEAL_COUNT_FAILURE";

// Benchmark
export const FETCH_BENCHMARK_SUCCESS = "FETCH_BENCHMARK_SUCCESS";
export const FETCH_BENCHMARK_FAILURE = "FETCH_BENCHMARK_FAILURE";
export const UPDATE_BENCHMARK_SUCCESS = "UPDATE_BENCHMARK_SUCCESS";
export const UPDATE_BENCHMARK_FAILURE = "UPDATE_BENCHMARK_FAILURE";

// Report
export const REPORT_FETCH_SUCCESS = "REPORT_FETCH_SUCCESS";
export const REPORT_FETCH_FAILURE = "REPORT_FETCH_FAILURE";

// Permissions
export const PERMISSION_FETCH_SUCCESS = "PERMISSION_FETCH_SUCCESS";
export const PERMISSION_FETCH_FAILURE = "PERMISSION_FETCH_FAILURE";
export const PERMISSIONS = "PERMISSIONS";
